import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import footerlogo from '../assets/images/footerlogo.png'
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate } from 'react-router-dom'
import { SET_PREVIOUS_ROUTE } from '../Redux/constants/userConstants'
import { Stack } from '@mui/system';
import { Avatar, Grid  } from '@mui/material'
import RegisterMenu from './RegisterMenu';
import Link from '@mui/material/Link';

const Footer = () => {

    // const { user } = useSelector(state => state.auth)
    // const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const TermsConditions = () => {
      setAnchorElNav(null);
      navigate('/termsconditions')
    };
    const PrivacyPolicy = () => {
      setAnchorElNav(null);
      navigate('/privacypolicy')
    };
    const CustomerRegister = () => {
      setAnchorElNav(null);
      navigate('/customerregistration')
    };
  
    const CompanyRegister = () => {
      setAnchorElNav(null);
      navigate('/b2bregistration')
    };


    return (
        <footer id="footer">
            <Container>

            <Grid container spacing={2}>
  <Grid item md={2} xs={12}>

    <div className="logo">
                                <img src={footerlogo} alt="footer-logo" />
                            </div>
  
  </Grid>
  <Grid item md={4} xs={12}>
  
    <h3 className='column_title'>About</h3>
                            <p>DSS Limo is a Global service provider covering major world markets, utilizing a connected Network of owned and Affiliated companies providing quality Chauffeured transportation services worldwide.</p>
  
  </Grid>
  <Grid item md={2} xs={12}>
   
    <h3 className='column_title'>Policies</h3>
                            <p className='link' onClick={TermsConditions} >Terms and Conditions</p>
                            <p className='link' onClick={PrivacyPolicy} >Privacy Policy</p>
                       
   
  </Grid>
  <Grid item md={2} xs={12}>
  
    <h3 className='column_title'>Contact Us</h3>
    <p>+65 12345678 </p>
    <p>info@dsslimousine.com</p>
                           
  
  </Grid>
   <Grid item md={2} xs={12}>
  
    <h3 className='column_title'>Register</h3>
    <p className='link' onClick={CustomerRegister}>Customer Register</p>
    <p className='link' onClick={CompanyRegister}>Company Register</p>
                           
  
  </Grid>
</Grid>
              
            </Container>
            <div className="copy-right">
                <p>Copyright DSS Limousine 2024.All rights reserved.</p>
            </div>
        </footer>
    )

}

export default Footer

