import React, { Fragment, useEffect, useState } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import SummaryDetails from './SummaryDetails'
import './style.scss'
import AddStop from './AddStop'
import SelectVehicle from './SelectVehicle'
import TopBar from '../../components/TopBar'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, RadioGroup, Typography } from '@mui/material'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getHourlyRate, getTripAvailability } from '../../Redux/actions/bookingActions'
import { LoadScript } from '@react-google-maps/api';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import moment from 'moment'
import GetId from '../ThirdParty/GetId'
import MyTransfer from '../ThirdParty/MyTransfer'
import VehicleList from '../VehicleList'
import { has } from 'lodash'
import { toast } from 'react-toastify'
import Footer from '../../components/Footer'
import HomeTopBar from '../../components/HomeTopBar';
const Summary = ({ head, props }) => {

    const dispatch = useDispatch();
    const [bookingData, setBookingData] = useState(null)
	 const [bookingType, setBookingType] = useState(null)

    const { enablegett, orderData } = useSelector(state => state.booking)
    const { user } = useSelector(state => state.auth)
    const [open, setOpen] = useState(false)

    const navigate = useNavigate()



    useEffect(() => {

        async function checkreturn() {
            const bookinData = await JSON.parse(localStorage.getItem("booking"));

            if (bookinData?.bookingType === "return") {
                setOpen(true)
            }
        }

        if (enablegett) {
            checkreturn()
        }


    }, [enablegett])


    const proceedOneway = async () => {
        setOpen(false)
        let bookinData = await JSON.parse(localStorage.getItem("booking"));
        bookinData.bookingType = "oneway";
        await localStorage.setItem("booking", JSON.stringify(bookinData))

        bookinData.waypoints = bookinData.waypoints ? bookinData.waypoints : [];
        setBookingData(bookinData);
        dispatch({
            type: BOOKING_DATA,
            payload: bookinData
        })

    }


    const cancelbooking = async () => {
        setOpen(false)
        setBookingData(null);
        dispatch({
            type: BOOKING_DATA,
            payload: null
        })

        await localStorage.removeItem("booking")
        navigate('/')
    }





    useEffect(() => {
        checkPricePlans()
    }, [])

    function calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = toRad(lat2 - lat1);
        var dLon = toRad(lon2 - lon1);
        var lat1 = toRad(lat1);
        var lat2 = toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;

        return d;
    }

    // Converts numeric degrees to radians
    function toRad(Value) {
        return Value * Math.PI / 180;
    }


    const checkPricePlans = async () => {
        let token = localStorage.getItem("token");
        if (token) {
            const bookinData = await JSON.parse(localStorage.getItem("booking"));

            bookinData.waypoints = bookinData.waypoints ? bookinData.waypoints : [];
            setBookingData(bookinData);
            dispatch({
                type: BOOKING_DATA,
                payload: bookinData
            })

            let extraseats = parseInt(bookinData?.baby) + parseInt(bookinData?.minors) + parseInt(bookinData?.childrens)

			var type='';
            if (bookinData.type === "point") {
				
				  if (bookinData?.location_from?.address && (bookinData.location_from.address.toLowerCase().includes('airport'))) {

                     type = 'airport';
                     setBookingType(type);
                }else if (bookinData?.location_to?.address && (bookinData.location_to.address.toLowerCase().includes('airport'))){
                     type = 'departure';
                     setBookingType(type);
                }else{

                     type =  (bookinData?.location_from?.type === 'airport') ? 'airport' : (bookinData?.location_to?.type === 'airport') ? 'departure' : 'point' ;
                     setBookingType(type);
                }
              
			  
                let datas = {
                    fromLat: bookinData?.location_from?.latitude.toFixed(16),
                    fromLng: bookinData?.location_from?.longitude.toFixed(16),
                    toLat: bookinData?.location_to?.latitude.toFixed(16),
                    toLng: bookinData?.location_to?.longitude.toFixed(16),
                    stops: bookinData?.stops ? bookinData?.stops.length : 0,
                    noOfPassengers: bookinData?.passenger,
                    date: moment(bookinData.pickupdate).format("DD-MM-YYYY HH:mm"),
                    user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
                     location_type: type,
                    bookingType:bookinData?.bookingType,
                    returnDate:moment(bookinData?.returndate).format("DD-MM-YYYY HH:mm"),
                    extraseats,
                    customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                    user_id: user?.id
                }

                if (moment(bookinData.pickupdate) > moment()) {
                    dispatch(getTripAvailability(datas))
                }
                else {
                    toast.info("Booking date time should be greater than current date time")
                }

            }
            else if (bookinData.type === "hour") {

                let distance;
                if (bookinData?.location_to !== null) {
                    distance = calcCrow(bookinData?.location_from?.latitude, bookinData?.location_from?.longitude, bookinData?.location_to?.latitude, bookinData?.location_to?.longitude);
                } else {
                    distance = 0;
                }
                let datas = {
                    km: distance.toFixed(2),
                    //hour: (bookinData?.durationFull?.value/3600).toFixed(1),
                    hour: bookinData?.duration,
                    noOfDays: bookinData?.duration * 1 < 24 ? 0 : (parseFloat(bookinData?.duration) / 24).toFixed(0),
                    noOfPassengers: bookinData?.passenger,
                    date: moment(bookinData.pickupdate).format("DD-MM-YYYY HH:mm"),
                    user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
                    location_type: (bookinData?.location_from?.type === 'airport' || bookinData?.location_to?.type === 'airport') ? 'airport' : 'point',
                    countryCode: bookinData?.location_from?.countryCode,
                    countryName: bookinData?.location_from?.countryName,
                    extraseats,
                    customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                    user_id: user?.id
                }

                if (moment(bookinData.pickupdate) > moment()) {
                    dispatch(getHourlyRate(datas))
                }
                else {
                    toast.info("Booking date time should be greater than current date time")
                }

            }
        }
    }


    return (
        <div>

        <Grid >
            <HomeTopBar/>
            <Banner/>
            <WorkFlow style={{ position: 'relative' }} activeStep={1} />
        </Grid>

         <Grid>
            {bookingData && <SummaryDetails data={bookingData} onAdd={checkPricePlans} />}
        </Grid>

            <Grid container spacing={2} className="container_topspace car-sel">
                <Grid item xs={12} md={12} sx={12} >
                    {/* <h4 className='underline'>Select Vehicle</h4> */}
					 {bookingType ==='airport'? <div style={{"textAlign":"center"}} className='free_waiting_time'>Instant confirmation and free waiting time 80 minutes after flight landed.</div>:null}
                    <h5 className="ride-s-h">Perfect Rides</h5>
                    <h2 className="ride-h">Our Best Cabs</h2>
                    {!enablegett && <SelectVehicle/>}
                    {!enablegett && <VehicleList />}
                    {enablegett && <GetId />}
                    {enablegett && (!orderData || orderData?.length === 0) && <div>
                        <GetId />
                        <MyTransfer />
                    </div>}

                </Grid>
                <Grid item xs={12} md={3} sx={12} >
                    {/* <h4 className='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#EDBA37' }}>Sum</span>mary</h4> */}


                    {/* {!enablegett && <div>
                            {bookingData?.location_to === null ? '' :
                            <AddStop onAdd={checkPricePlans} />}
                    </div>} */}

                </Grid>

            </Grid>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={open}
            >
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    Return trip not available. Do you want to proceed with one way?
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                        onClick={cancelbooking}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={proceedOneway}
                    >Yes</Button>
                </DialogActions>
            </Dialog>
            <Footer/>
        </div>
    )
}

export default Summary
