import React, { useEffect } from 'react'
import TopBar from '../../components/TopBar'
import { Container } from '@mui/system'
import TableRow from '@mui/material/TableRow'
import HomeHeader from '../../components/HomeHeader'
import { Grid, Typography, Box } from '@mui/material'
import './style.scss'
import HomeTopBar from '../../components/HomeTopBar'
import Footer from '../../components/Footer'
import BookingDetails from '../Booking/BookingDetails'
import Hoursupport from '../../assets/images/24hour-support.png'
import Quality from '../../assets/images/quality.png'
import Gps from '../../assets/images/gps.png'
import Excellent from '../../assets/images/excellent.png'
import safe_secure from '../../assets/images/safe_secure.png'
import best_quality from '../../assets/images/best_quality.png'
import car from '../../assets/images/car.png'
import star_one from '../../assets/images/star_one.png'
import star_two from '../../assets/images/star_two.png'
import multistar from '../../assets/images/multistar.jpg'
import point_star from '../../assets/images/point_star.png'
import best_ten from '../../assets/images/best_ten.png'
import best_nine from '../../assets/images/best_nine.png'
import best_seven from '../../assets/images/best_seven.png'
import best_six from '../../assets/images/best_six.png'
import best_five from '../../assets/images/best_five.png'
import best_four from '../../assets/images/best_four.png'
import best_three from '../../assets/images/best_three.png'
import best_two from '../../assets/images/best_two.png'
import best_one from '../../assets/images/best_one.png'
import vector_one from '../../assets/images/vector_one.png'
import vector_two from '../../assets/images/vector_two.png'
import header_car from '../../assets/images/header_car.png'
import homebgchange from '../../assets/images/home-bg-change.jpg'

import ride_img_one from '../../assets/images/ride_img_one.jpg'
import ride_img_two from '../../assets/images/ride_img_two.jpg'
import ride_img_three from '../../assets/images/ride_img_three.png'
import ride_img_four from '../../assets/images/ride_img_four.jpg'
import Vector_10 from '../../assets/images/Vector_10.png'
import Group_11 from '../../assets/images/Group_11.png'
import light_car_bg from '../../assets/images/light_car_bg.jpg'
import refresh from '../../assets/images/refresh.png'
import gps_one from '../../assets/images/gps_one.png'
import medal_star from '../../assets/images/medal_star.png'

import ride_bg from '../../assets/images/ride_bg.png'
import BookingForm from './BookingForm'
import { useDispatch, useSelector } from 'react-redux'
import { BOOKING_INPUT, COUPON_VERIFY_FAIL } from '../../Redux/constants/bookingConstants';
import { redirect, useNavigate } from 'react-router-dom'
const Home = () => {
    //process.env.REACT_APP_GOOGLE_KEY

    const navigation = useNavigate()

    const { user } = useSelector(state => state.auth)
	 const dispatch = useDispatch()
    useEffect(() => {
        deleteBooking()
    }, [user])

    const deleteBooking = () => {
      
        // let orderDatas = orderData.filter((or, i) => i !== index)
        let orderDatas ='';
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'orderData',
                value: orderDatas
            }
        })

    }

    useEffect(() => {
        if (user) {
            navigation('/dashboard')
        }
    }, [user])


    return (
        <div>
            <div className="mainHeader bg-color-add">
                <Grid container className="header_container">
                    {/* <HomeHeader /> */}
                    <HomeTopBar activeMenu='1' />
                </Grid>
            </div>
            <div className="headerCon">
                <div className="homeBackground">
                    <Grid className='service' container sx={{ height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap', alignItems: 'top' }}>
                        {/* <Grid xs={12} md={12} sx={{}} >
                            <h1 className='heading' >Enjoy your Life with our<br />Comfortable Cars</h1>
                        </Grid> */}
                        <div className="bg-orange">

                            <Container>
                                <Grid container spacing={2} id="sec-con">
                                    <Grid item xs={4} md={4} sx={12}>
                                        <div className="sub-box">

                                            <div className="imges">
                                                <img src={refresh} style={{}} />

                                            </div>
                                            <div className="sub-con">
                                                <h6>24 Hours Support</h6>
                                                <p>We support you <br /> all hours of the day.</p>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} md={4} sx={12}>
                                        <div className="sub-box">
                                            <div className="imges">
                                                <img src={medal_star} style={{}} />

                                            </div>
                                            <div className="sub-con">
                                                <h6>Qualified Assurance</h6>
                                                <p>All cars have a <br /> valid insurance.</p>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} md={4} sx={12} >
                                        <div className="sub-box">
                                            <div className="imges">
                                                <img src={gps_one} style={{}} />

                                            </div>
                                            <div className="sub-con">
                                                <h6>GPS on Cars</h6>
                                                <p>All cars are equipped with <br /> GPS navigation system.</p>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>

                        </div>

                    </Grid>
                </div>
                <div className="header-right">
                    <div className="header-content">
                        <h5 className="Header-h">Enjoy your life with our comfortable cars</h5>
                        <div className="vector-one">
                            <img src={vector_one} alt="image" />
                        </div>
                        <div className="vector-two">
                            <img src={vector_two} alt="image" />
                        </div>
                    </div>
                    <div className="header-sm-img">
                        <div className="header-car">
                            <img src={header_car} alt="image" />
                        </div>
                    </div>
                    <div className="search-bar">
                        <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
                            <BookingForm />
                        </Grid>
                    </div>
                </div>
            </div>

            <div className="why-chooseus">
                <Container className="container">
                    <h2 className="section_head text-center">Why choose Us</h2>
                    <Grid container spacing={2} className="cen">

                        <Grid item xs={12} md={4} sx={12} >
                            <div className="sub-box-3">
                                <div className="imges-3">
                                    <img src={Excellent} alt="image" />
                                </div>
                                <div className="sub-content">
                                    <h4>Excellent Service</h4>
                                    <p>At the heart of our service philosophy is a deep commitment to putting our customers first.We
                                        listen,understand.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                            <div className="sub-box-3">
                                <div className="imges-3">
                                    <img src={safe_secure} alt="image" />
                                </div>
                                <div className="sub-content">
                                    <h4>Safe & Secure</h4>
                                    <p>Our commitment to safety begins with our fleet of vehicles.Regular maintenance checks, stringent
                                        inspections.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sx={12}>
                            <div className="sub-box-3">
                                <div className="imges-3">
                                    <img src={best_quality} alt="image" />
                                </div>
                                <div className="sub-content">
                                    <h4>Best Quality</h4>
                                    <p>our commitment to quality begins with our fleet of vehicles. Meticulously maintained and regularly
                                        inspected.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="perfect-ride bg-secondary">
                <Container className="container">
                    <Grid container className="row sec-pad">

                        <Grid item xs={12} md={5} sx={12} className="pos-rel">
                            <div className="empty-back">

                            </div>
                            <div className="ride-bg">
                                <svg width="643" height="559" viewBox="0 0 643 559" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.0864 168.633C-48.1136 351.833 69.7531 445.633 137.586 469.633C167.987 478.833 215.586 509.8 235.586 524.133C295.586 572.533 387.919 558.966 426.586 546.133C566.186 501.733 627.753 322.633 641.086 238.633C658.286 33.833 505.253 -4.70036 426.586 1.63298C362.186 18.433 286.086 13.2997 256.086 8.633C126.486 -11.767 46.7529 106.8 23.0864 168.633Z" fill="#FAEBC3" stroke="#FAEBC3" />
                                </svg>
                            </div>
                            <div className="car-img">
                                <img src={car} alt="image" />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7} sx={12} className="ride-content">
                            <div className="star-dec">
                                <div className="star-one">
                                    <img src={star_two} alt="image" />
                                </div>
                                <div className="star-two">
                                    <img src={star_one} alt="image" />
                                </div>
                                <div className="multistar">
                                    <img src={multistar} alt="image" />
                                </div>
                            </div>
                            <p className="pre_head">PERFECT RIDES</p>
                            <h4 className="section_head">Best Travel Experience</h4>
                            <p className="para">At DSS Limo we pride ourselves delivering extensive services to fullfill
                                all of your needs with first rate cutomer care. our goal is to make your travels safe, effortless
                                and on schedule</p>
                            <ul>
                                <li><span><img src={point_star} alt="image" /></span> Premium Quality</li>
                                <li><span><img src={point_star} alt="image" /></span> Safe Journey</li>
                                <li><span><img src={point_star} alt="image" /></span> Well Professional Drivers</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="best-ride">
                <Container className="container">
                    <Grid className="" xs={12} md={4} sx={12}>
                        <h2 className="section_head text-center">The best deserver the best rides</h2>
                        <div className="best-ride-con" sm={{ flexDirection: 'column' }}>
                            <div className="best-ride-img">
                                <img src={best_ten} alt="image" />
                                <p>Airport Transfer</p>
                            </div>
                            <div className="best-ride-img">
                                <img src={best_nine} alt="image" />
                                <p>Casinos</p>
                            </div>
                            <div className="best-ride-img">
                                <img src={best_seven} alt="image" />
                                <p>Night Parties</p>
                            </div>
                            <div className="best-ride-img">
                                <img src={best_six} alt="image" />
                                <p>Wedding Parties</p>
                            </div>
                            <div className="best-ride-img">
                                <img src={best_five} alt="image" />
                                <p>Events</p>
                            </div>
                            <div className="best-ride-img">
                                <img src={best_four} alt="image" />
                                <p>Shopping</p>
                            </div>
                            <div className="best-ride-img">
                                <img src={best_three} alt="image" />
                                <p>Hospital</p>
                            </div>
                            <div className="best-ride-img">
                                <img src={best_two} alt="image" />
                                <p>Foreign Workers</p>
                            </div>
                            {/* <div className="best-ride-img">
                                <img src={best_one} alt="image" />
                                <p>Foreign Workers</p>
                            </div> */}
                        </div>
                    </Grid>
                </Container>
            </div>
            <div className='Best-cab'>
                <Grid className=''>
                    <Grid className='car-bg-con'>
                        <Container className="container">
                            <div className='ride-head'>
                                <p>PERFECT RIDE</p>
                                <h4>Our Best Cabs</h4>
                            </div>
                            <Grid container className="row">
                                <Grid item xs={12} md={3} sx={12} className="ride-pos-rel">
                                    <div className='ride-card-con'>
                                        <div className="ride-img">
                                            <img src={ride_img_one} alt="image" />
                                        </div>
                                        <div className="car-ride-content">
                                            <h4>Standard car | 4 sheater</h4>
                                            <Typography>Discover refined elegance in our 4 seater limo, offering intimate luxury for a personalized</Typography>
                                        </div>
                                        <Grid >
                                            <div className='star-holder'>
                                                <div className='p-star-con'>
                                                    <div className='p-star'>
                                                        <Typography>Prize Start From</Typography>
                                                        <Typography>$194/per day</Typography>
                                                    </div>
                                                    {/* <div className='ride-star-con'>
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Group_11} alt="image" />
                                                            <div className='star-num'>(1451)</div>
                                                        </div> */}
                                                </div>
                                                <div className='ride-star-btn'>
                                                    <a href="#">Check Now</a>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3} sx={12} className="ride-pos-rel">
                                    <div className='ride-card-con'>
                                        <div className="ride-img">
                                            <img src={ride_img_two} alt="image" />
                                        </div>
                                        <div className="car-ride-content">
                                            <h4>Standard car | 4 sheater</h4>
                                            <Typography>Discover refined elegance in our 4 seater limo, offering intimate luxury for a personalized</Typography>
                                        </div>
                                        <Grid >
                                            <div className='star-holder'>
                                                <div className='p-star-con'>
                                                    <div className='p-star'>
                                                        <Typography>Prize Start From</Typography>
                                                        <Typography>$194/per day</Typography>
                                                    </div>
                                                    {/* <div className='ride-star-con'>
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Group_11} alt="image" />
                                                            <div className='star-num'>(1451)</div>
                                                        </div> */}
                                                </div>
                                                <div className='ride-star-btn'>
                                                    <a href="#">Check Now</a>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3} sx={12} className="ride-pos-rel">
                                    <div className='ride-card-con'>
                                        <div className="ride-img">
                                            <img src={ride_img_three} alt="image" />
                                        </div>
                                        <div className="car-ride-content">
                                            <h4>Standard car | 4 sheater</h4>
                                            <Typography>Discover refined elegance in our 4 seater limo, offering intimate luxury for a personalized</Typography>
                                        </div>
                                        <Grid >
                                            <div className='star-holder'>
                                                <div className='p-star-con'>
                                                    <div className='p-star'>
                                                        <Typography>Prize Start From</Typography>
                                                        <Typography>$194/per day</Typography>
                                                    </div>
                                                    {/* <div className='ride-star-con'>
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Group_11} alt="image" />
                                                            <div className='star-num'>(1451)</div>
                                                        </div> */}
                                                </div>
                                                <div className='ride-star-btn'>
                                                    <a href="#">Check Now</a>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3} sx={12} className="ride-pos-rel">
                                    <div className='ride-card-con'>
                                        <div className="ride-img">
                                            <img src={ride_img_four} alt="image" />
                                        </div>
                                        <div className="car-ride-content">
                                            <h4>Standard car | 4 sheater</h4>
                                            <Typography>Discover refined elegance in our 4 seater limo, offering intimate luxury for a personalized</Typography>
                                        </div>
                                        <Grid >
                                            <div className='star-holder'>
                                                <div className='p-star-con'>
                                                    <div className='p-star'>
                                                        <Typography>Prize Start From</Typography>
                                                        <Typography>$194/per day</Typography>
                                                    </div>
                                                    {/* <div className='ride-star-con'>
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Vector_10} alt="image" />
                                                            <img src={Group_11} alt="image" />
                                                            <div className='star-num'>(1451)</div>
                                                        </div> */}
                                                </div>
                                                <div className='ride-star-btn'>
                                                    <a href="#">Check Now</a>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                        <Grid className='car-bg'>
                            {/* <img src={light_car_bg} alt="image" /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

export default Home
