import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TripList from './TripList';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../Redux/constants/tripConstants';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CarImg from '../../assets/images/IconsNew/car.png'
import moment from 'moment'
import { getTripsByDate } from '../../Redux/actions/tripActions';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomDate from '../../components/CustomDate';
import Grid from '@mui/material/Grid';
import customAxios from '../../CustomAxios';
import { CREDIT_TERMS_SUCCESS } from '../../Redux/constants/bookingConstants';
const StyledTabs = styled((props) => (
	<Tabs
		style={{ height: "32px" }}
		{...props}
		sx={{
			'& .MuiTabs-flexContainer': {
				flexWrap: 'wrap',
				height: 30
			},

		}}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		display: 'none',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		maxWidth: 50,
		width: '70%',
		backgroundColor: '#333333',
	},
});

const StyledTab = styled((props) =>
	<Tab disableRipple {...props} />)(
		({ theme }) => ({
			textTransform: 'none',
			fontFamily: 'nunito',
			fontWeight: '700',
			boxShadow: 1,
			fontSize: theme.typography.pxToRem(12),
			marginRight: 0,
			//borderRadius: '2px',
			//width: '70%',
			marginTop: 0,
			backgroundColor: '#EDBA37',
			color: '#333333',
			'&.Mui-selected': {
				color: '#fff',
				backgroundColor: '#333333',
				//width: 200
			},

		}),
	);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box >
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function TripHistory({ createBooking, editBooking }) {

	const [value, setValue] = React.useState(0);
	const { user } = useSelector(state => state.auth)
	const [balance, setBalance] = useState(0)
	const [currencySymbol, setcurrencySymbol] = useState('S$');
	const { tripLists, deleteSuccess, error } = useSelector(state => state.trip)
	const [tripType, setTripeType] = React.useState(null);
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')
	const navigate = useNavigate();
	const dispatch = useDispatch()




	const schema = yup.object({
		fromDate: yup.string(),
		toDate: yup.string()
	}).required();

	useEffect(() => {
		setcurrencySymbol(localStorage.getItem("usercurrency"));
		async function getCreditBalance() {
			let userId = user?.parentDetails ? user?.parentDetails?.id : user?.id;

			let balance = await customAxios.get(`getbalance/${userId}`)
			setBalance(balance?.data?.data)

		
		}

		if (user?.role === "corporate") {
			getCreditBalance()
		}
	}, [user])



	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver()
	});
	// useEffect(() => {
	//   let id=user?.id
	//   dispatch(getTrip(id))
	// }, [])

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [error])


	const handleChange = (event, newValue) => {
		setValue(newValue);
		switch (newValue) {
			case 0:
				setTripeType('onGoingTrips')
				break;
			case 1:
				setTripeType('upComingTrips')
				break;
			case 2:
				setTripeType('completedTrips')
				break;
			default:
				break;
		}
	};

	const onSubmit = (fromDate, toDate) => {
		let userId = user.id
		dispatch(getTripsByDate(fromDate, toDate, userId))
	};

	return (
		<Box xs={6} md={12} className='tripBox'>
			<Box display={{ xs: 'block', md: 'none' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between',flexWrap:'wrap'}}>
					<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5 }}>
						<StyledTabs
							value={value}
							onChange={handleChange}
							variant="scrollable"
							aria-label="basic tabs example"
							textColor="secondary"
							indicatorColor="secondary"
							className='tabheight'
						>
							<StyledTab label="ONGOING" className='tabheight' {...a11yProps(0)} style={{ fontFamily: 'Nunito', fontWeight: 'bold', maxWidth: '10%' }} />
							<StyledTab label="UPCOMING" className='tabheight' {...a11yProps(1)} style={{ fontFamily: 'Nunito', fontWeight: 'bold', maxWidth: '10%' }} />
							<StyledTab label="COMPLETED" className='tabheight' {...a11yProps(2)} style={{ fontFamily: 'Nunito', fontWeight: 'bold', maxWidth: '10%' }} />
						</StyledTabs>

					</div>
					{user?.role === "corporate" && <Typography className='creditText' sx={{ fontSize: '25px', paddingLeft: 2, paddingRight: 2 }} >{`Credit Balance: ${currencySymbol} ${balance}`}</Typography>}
					{/* <Button
						variant="contained"
						endIcon={<img src={CarImg} style={{ width: 20, height: 20 }} />}
						style={{ color: 'white', backgroundColor: '#333333', borderRadius: 1, height: 30 }}
						onClick={() => navigate("/newbooking")}
						className="newbooking"
					>
						NEW BOOKING
					</Button> */}
				</div>

				{tripType !== "onGoingTrips" && <form onSubmit={handleSubmit(onSubmit)}>

					<Grid container py={1} >
						<Grid item xs={12} sm={3} md={3}>
							{tripType == "upComingTrips" &&
								<div style={{ display: 'flex', alignItems: 'center' }}>

									<CustomDate label={'From'} changeDate={(value) => setFromDate(value)} minDate={moment().toDate()} />
								</div>

							}
							{tripType == "completedTrips" &&
								<div style={{ display: 'flex', alignItems: 'center' }}>

									<CustomDate label={'From'} changeDate={(value) => setFromDate(value)} />
								</div>
							}
						</Grid>
						<Grid item xs={12} sm={3} md={3} >
							{tripType == "upComingTrips" &&
								<div style={{ display: 'flex', alignItems: 'center' }}>

									<CustomDate label={'To'} changeDate={(value) => setToDate(value)} minDate={moment().toDate()} />
								</div>

							}
							{tripType == "completedTrips" &&
								<div style={{ display: 'flex', alignItems: 'center' }}>

									<CustomDate label={'To'} changeDate={(value) => setToDate(value)} />
								</div>
							}
						</Grid>
					</Grid>
				</form>}

			</Box>
			<Box display={{ xs: 'none', md: 'block' }} >
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<StyledTabs
							className='tabheight'
							value={value}
							onChange={handleChange}
							variant="scrollable"
							aria-label="basic tabs example"
							textColor="secondary"
							indicatorColor="secondary"
						>
							<StyledTab className='tabheight' label="ONGOING" {...a11yProps(0)} style={{ maxWidth: '13%' }} />
							<StyledTab className='tabheight' label="UPCOMING" {...a11yProps(1)} style={{ maxWidth: '13%' }} />
							<StyledTab className='tabheight' label="COMPLETED" {...a11yProps(2)} style={{ maxWidth: '13%' }} />

						</StyledTabs>
						{user?.role === "corporate" && <Typography className='creditText' sx={{ fontSize: '25px', paddingLeft: 2, paddingRight: 2 }} >{`Credit Balance: ${currencySymbol} ${balance}`}</Typography>}
						{tripType !== "onGoingTrips" && <form onSubmit={handleSubmit(onSubmit)}>

							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
								<div >
									{tripType == "upComingTrips" &&
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >From</Typography>
											<CustomDate changeDate={(value) => setFromDate(value)} minDate={moment().toDate()} />
										</div>

									}
									{tripType == "completedTrips" &&
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >From</Typography>
											<CustomDate changeDate={(value) => setFromDate(value)} />
										</div>
									}
								</div>
								<div >
									{tripType == "upComingTrips" &&
										<div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >To</Typography>
											<CustomDate changeDate={(value) => setToDate(value)} minDate={moment().toDate()} />
										</div>

									}
									{tripType == "completedTrips" &&
										<div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >To</Typography>
											<CustomDate changeDate={(value) => setToDate(value)} />
										</div>
									}
								</div>
							</div>
						</form>}
					</div>

					{createBooking && <Button
						variant="contained"
						startIcon={<img src={CarImg} style={{ width: 25, height: 25 }} />}
						style={{ }}
						onClick={() => navigate("/newbooking")}
						className="newbooking"
					>
						NEW BOOKING
					</Button>}
				</div>
			</Box>
			<TabPanel value={value} index={0}  >
				<TripList tripType="onGoingTrips" editBooking={editBooking} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<TripList tripType="upComingTrips" editBooking={editBooking} fromDate={fromDate} toDate={toDate} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<TripList tripType="completedTrips" editBooking={editBooking} fromDate={fromDate} toDate={toDate} />
			</TabPanel>
		</Box>
	);
}
