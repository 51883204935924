import React, { Fragment, useState, useEffect } from 'react'
import './style.scss'
import { Card, Typography, ButtonGroup, Button } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { height } from '@mui/system';
import Vehicle1Img from '../../assets/images/Vehi1.jpg'
import Vehicle2Img from '../../assets/images/Vehi2.jpg'
import Vehicle3Img from '../../assets/images/Vehi3.jpg'
import Vehicle4Img from '../../assets/images/Vehi4.jpg'
import Vehicle5Img from '../../assets/images/Vehi5.jpeg'
import CarImg from '../../assets/images/IconsNew/sedan-car-front.png'
import AdultImg from '../../assets/images/Icons/Adult.png'
import AgegroupImg from '../../assets/images/IconsNew/age-group.png'
import BagImg from '../../assets/images/IconsNew/luggage.png'
import HandBagImg from '../../assets/images/Icons/suitcase.png'
import ViewMap from './ViewMap';
import CachedIcon from '@mui/icons-material/Cached';
import Stack from '@mui/material/Stack';
import PassengerDetails from '../PassengerDetails';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants';
import moment from 'moment';
import { checkAirportType } from '../../Redux/actions/bookingActions';
const SelectVehicle = () => {

    const { availableVehicles, bookingData } = useSelector(state => state.booking)
    const { user, additionalStops } = useSelector(state => state.auth)
    const { markupLists } = useSelector(state => state.markup)
    const dispatch = useDispatch()






    const navigate = useNavigate();


    const selectCurrentVehicle = (data) => {

        let checkdata = {
            id: data?.priceplan_id,
            fromLat: bookingData?.location_from?.latitude,
            fromLng: bookingData?.location_from?.longitude,
            toLat: bookingData?.location_to?.latitude,
            toLng: bookingData?.location_to?.longitude,
            date: moment(bookingData?.pickupdate).format("DD-MM-YYYY HH:mm")
        }

        //dispatch(checkAirportType(checkdata))

        dispatch({
            type: BOOKING_TYPE,
            payload: 'PAL'
        })

        let agentmarkup = 0;
        let adminMarkup = 0
        let tax = 0;
        let total = 0;
        let additionalStop = 0;
        let baserate = 0;
        let extras = 0;
        let extraCharges = null;
        let toll = data?.tollrate ? parseFloat(data?.tollrate) : 0;

        extraCharges = data?.extraCharges?.map(extra => {
            if (extra?.rate_type === "percentage") {
                extras += bookingData?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
                return {
                    id: extra?.addonitem?.id,
                    name: extra?.addonitem?.name,
                    price: bookingData?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
                }
            }
            else {
                extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
                return {
                    id: extra?.addonitem?.id,
                    name: extra?.addonitem?.name,
                    price: parseFloat(extra?.rate)
                }
            }
        })

        if (user?.role === "agent" && markupLists?.type === "fixed") {
            additionalStop = bookingData?.stops ? bookingData?.stops?.length * additionalStops ? additionalStops : 20 : 0;
            baserate = bookingData?.type === "hour" ? (parseFloat(data?.rate) * parseFloat(bookingData?.duration)) + additionalStop : parseFloat(data?.rate) + additionalStop



            if (markupLists?.status === 1) {
                agentmarkup = parseFloat(markupLists?.value);
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.defaultMarkup?.value)
            }

            tax = ((baserate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(data?.tax?.value)

            total = parseFloat(baserate + adminMarkup + agentmarkup + extras + tax + toll);
        }
        else {
            additionalStop = bookingData?.stops ? bookingData?.stops?.length * additionalStops ? additionalStops : 20 : 0;
            baserate = bookingData?.type === "hour" ? (parseFloat(data?.rate) * parseFloat(bookingData?.duration)) + additionalStop : parseFloat(data?.rate) + additionalStop



            if (user?.role === "agent" && markupLists?.status === 1) {
                agentmarkup = ((baserate + extras + toll) / 100) * parseFloat(markupLists?.value)
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll) / 100) * parseFloat(data?.defaultMarkup?.value)
            }

            tax = ((baserate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(data?.tax?.value)

            total = parseFloat(baserate + adminMarkup + agentmarkup + extras + tax + toll);
        }

        let datas = {
            ...data,
            price: baserate,
            adminMarkup,
            agentmarkup,
            extraRate: extras,
            total: total,
            taxValue: tax,
            addonItem: data?.extraCharges,
            toll: toll,
            extraCharges

        }

        dispatch({
            type: SELECTED_VEHICLE,
            payload: datas
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedVehicle: datas,
                id: 'current'
            }
        })

        navigate("/passengerdetails")
    }

    return (

        <div >
            <Grid container spacing={4}>
                <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                    {/* <Typography className='selectvehiText' >All prices are in Singapore dollar (SGD)</Typography> */}
                </Grid>

                {/* <Grid xs={12} md={5} sx={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center' }}>
                        <span>
                            <CachedIcon style={{ fontSize: '40px', color: '#EDBA37' }} />
                        </span>
                        <span>
                            <Typography className='loctextbold'>Refresh List</Typography>
                        </span>
                    </div>
                </Grid> */}
            </Grid>

            <Grid>
                {availableVehicles && availableVehicles.map((vehicle, index) => {
                    if (vehicle?.vehicledetails) {
                        let agentmarkup = 0;
                        let adminMarkup = 0
                        let tax = 0;
                        let total = 0;
                        let additionalStop = 0;
                        let extras = 0;
                        let toll = vehicle?.tollrate ? parseFloat(vehicle?.tollrate) : 0;

                        additionalStop = bookingData?.stops ? bookingData?.stops?.length * additionalStops ? additionalStops : 20 : 0;

                        let rate = bookingData?.type === "hour" ? (parseFloat(vehicle?.rate) * parseFloat(bookingData?.duration)) + additionalStop : parseFloat(vehicle?.rate) + additionalStop

                        vehicle?.extraCharges?.map(extra => {
                            if (extra?.rate_type === "percentage") {
                                extras += bookingData?.type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            }
                            else {
                                extras += bookingData?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(bookingData?.duration)) : parseFloat(extra?.rate)
                            }
                        })


                        if (user?.role === "agent" && markupLists?.type === "fixed") {





                            if (markupLists?.status === 1) {
                                agentmarkup = parseFloat(markupLists?.value);
                            }

                            if (vehicle?.markUpDetails && vehicle?.markUpDetails?.length > 0) {
                                if (vehicle?.markUpDetails[0]?.value_type === "Percentage") {
                                    adminMarkup = ((rate + extras + toll) / 100) * parseFloat(vehicle?.markUpDetails[0]?.value)
                                }
                                else {
                                    adminMarkup = parseFloat(vehicle?.markUpDetails[0]?.value)
                                }
                            }
                            else if (vehicle?.defaultMarkup) {
                                adminMarkup = ((rate + extras + toll) / 100) * parseFloat(vehicle?.defaultMarkup?.value)
                            }

                            if (bookingData?.bookingType === "return") {
                                rate = rate * 2;
                                adminMarkup = adminMarkup * 2;
                                extras = extras * 2;
                                toll = toll * 2;
                            }

                            tax = ((rate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(vehicle?.tax?.value)



                            total = parseFloat(rate + adminMarkup + agentmarkup + tax + extras + toll);
                        }
                        else {


                            if (bookingData?.bookingType === "return") {
                                rate = rate * 2;
                                adminMarkup = adminMarkup * 2;
                                extras = extras * 2;
                                toll = toll * 2;
                            }



                            if (user?.role === "agent" && markupLists?.status === 1) {
                                agentmarkup = ((rate + extras + toll) / 100) * parseFloat(markupLists?.value)
                            }

                            if (vehicle?.markUpDetails && vehicle?.markUpDetails?.length > 0) {
                                if (vehicle?.markUpDetails[0]?.value_type === "Percentage") {
                                    adminMarkup = ((rate + extras + toll) / 100) * parseFloat(vehicle?.markUpDetails[0]?.value)
                                }
                                else {
                                    adminMarkup = parseFloat(vehicle?.markUpDetails[0]?.value)
                                }
                            }
                            else if (vehicle?.defaultMarkup) {
                                adminMarkup = ((rate + extras + toll) / 100) * parseFloat(vehicle?.defaultMarkup?.value)
                            }

                            tax = ((rate + adminMarkup + agentmarkup + extras + toll) / 100) * parseFloat(vehicle?.tax?.value)


                            total = parseFloat(rate + adminMarkup + agentmarkup + tax + extras + toll).toFixed(2);
                        }
                        return (
                            <div key={index} style={{ marginTop: 15, marginBottom: 70 }}>
                                <Grid xs={12} md={12} sx={{ flexGrow: 1 }} >
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
                                        <Typography className='nameText'>{vehicle?.vehicledetails?.vehicle_type?.vehicle_type}</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
                                        <Typography className='classText'>{vehicle?.vehicledetails?.veh_details?.brand}</Typography>
                                    </div>

                                </Grid>
                                <Divider />
                                <Stack flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} borderRadius="20px" justifyContent={"space-between"}>
                                    <Grid container spacing={2} alignItems="center" justifyContent={"space-between"} width="100%">
                                        <Grid xs={12} md={4} flexDirection={"column"}>
                                            {
                                                vehicle?.vehicledetails?.description && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 35 }}>
                                                    <img src={CarImg} style={{ width: 30, height: 30 }} />
                                                    <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>{vehicle?.vehicledetails?.description}</Typography>
                                                </div>
                                            }

                                            <Box display={"flex"} flexDirection={{ xs: 'row-reverse', md: 'column' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', marginBottom: 35 }}>
                                                    <img src={AgegroupImg} style={{ width: 30, height: 30 }} />
                                                    <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max. {vehicle?.vehicledetails?.no_of_passenger}</Typography>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                                    <img src={BagImg} style={{ width: 30, height: 30 }} />
                                                    <Typography className='descriptionText' style={{ flexWrap: 'wrap', marginLeft: 30 }}>Max. {vehicle?.vehicledetails?.no_of_bag}</Typography>
                                                </div>
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} md={4}>
                                            {vehicle?.vehicledetails?.vehicle_images?.length > 0 ? <img src={`${vehicle.imageBasePath}${vehicle?.vehicledetails?.vehicle_images[0].path}`} style={{ width: 400, height: 250 }} /> : <img src={Vehicle1Img} style={{ width: 400, height: 250 }} />}
                                        </Grid>
                                        <Grid xs={12} md={4} >
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 5, paddingBottom: 5 }}>
                                                <ButtonGroup
                                                    orientation="vertical"
                                                    style={{ borderRadius: '45px' }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            color: '#333333',
                                                            backgroundColor: '#F5F5F5',
                                                            height: 100,
                                                            width: 200,
                                                            fontSize: '30px',
                                                            fontFamily: 'Tiempos Headline',
                                                            fontWeight: 'bold',
                                                            borderTopRightRadius: 45,
                                                            borderTopLeftRadius: 45
                                                        }}
                                                        onClick={() => selectCurrentVehicle(vehicle)}
                                                    >
                                                        S$ {parseFloat(total).toFixed(2)}
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor: '#333333',
                                                            height: 50,
                                                            width: 200,
                                                            borderBottomLeftRadius: 45,
                                                            borderBottomRightRadius: 45
                                                        }}
                                                        onClick={() => selectCurrentVehicle(vehicle)}
                                                        className='buttonText'
                                                    >
                                                        SELECT
                                                    </Button>

                                                </ButtonGroup>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </div>
                        )
                    }
                })}
            </Grid>
        </div>



    )
}

export default SelectVehicle

