import React, { Fragment, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import './style.scss'
import { Accordion, Button, Card, Stack, Typography, AccordionDetails, Container } from '@mui/material'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import AlarmIcon from '@mui/icons-material/Alarm';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimeImg from '../../assets/images/IconsNew/timetable.png'
import Hour from '../../assets/images/IconsNew/hour.svg'
import DistanceImg from '../../assets/images/IconsNew/distance.png'
import ArrowDownImg from '../../assets/images/IconsNew/arrowdown.png'
import moment from 'moment';
import axios from 'axios';
import { GoogleMap, DirectionsRenderer, DirectionsService } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import MainStop from './MainStop';
import SubStop from './SubStop';
import { BOOKING_DATA, RESET_ERROR } from '../../Redux/constants/bookingConstants';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';




const SummaryDetails = ({ onAdd }) => {
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<img src={ArrowDownImg} style={{ width: 30, height: 30, borderRadius: '15px', backgroundColor: 'white' }} />}
            {...props}
        />
    ))(({ theme }) => ({

        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
            //marginLeft: theme.spacing(0)
            zIndex: 2
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            zIndex: 2
        },
        '& .MuiAccordionSummary-content': {
            //marginLeft: theme.spacing(-4),  
        },
    }));
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')
    const [directions, setDirections] = useState('');
    const [load, setLoad] = useState(false);
    const [stops, setStops] = useState(0)

    const dispatch = useDispatch()

    const { bookingData: data, bookingChanged } = useSelector(state => state.booking)





    useEffect(() => {
        //getDirections(data)
        dispatch({
            type: RESET_ERROR
        })
    }, [bookingChanged])


    // const getDirections = async (data) => {
    //     let points = '';
    //     if (data?.waypoints && data?.waypoints?.length > 0) {
    //         data?.waypoints?.map(way => {
    //             points += `%7c${way?.location?.lat},${way?.location?.lng}`;
    //         })
    //     }

    //     let url = points ? `https://maps.googleapis.com/maps/api/directions/json?origin=${data?.location_from?.address}&waypoints=optimize:true${points}&destination=${data?.location_to?.address}&key=${process.env.REACT_APP_GOOGLE_KEY}` : `https://maps.googleapis.com/maps/api/directions/json?origin=${data?.location_from?.address}&destination=${data?.location_to?.address}&key=${process.env.REACT_APP_GOOGLE_KEY}`
    //     if (data?.location_from?.address && data?.location_to?.address) {
    //         await axios.get(url)
    //             .then(response => {
    //                 setDirections(response?.data)
    //                 let distance = 0, duration = 0;
    //                 response?.data.routes[0].legs.map(leg => {
    //                     distance += leg.distance.value
    //                     duration += leg.duration.value
    //                 })
    //                 setDistance(`${(distance / 1000).toFixed(1)} km`);
    //                 setDuration(`${(duration / 60).toFixed(1)} min`)
    //             })
    //             .catch(err => {

    //             })
    //     }
    // }

    const removeExtraStop = async (index) => {
        let details = data.stops.filter((item, i) => i !== index);
        data.stops = details
        data.waypoints = details.map(way => {
            return {
                location: {
                    lat: way?.stop_name?.latitude,
                    lng: way?.stop_name?.longitude
                }
            }
        });
        await localStorage.setItem("booking", JSON.stringify(data));

        dispatch({
            type: BOOKING_DATA,
            payload: data
        })
        onAdd()

    }


    const directionsCallback = (response) => {


        if (response !== null) {
            if (response.status === 'OK') {
                if (!directions || data?.stops.length !== stops) {
                    setStops(data?.stops?.length)
                    setDirections(response)
                }

                let distance = 0, duration = 0;
                response.routes[0].legs.map(leg => {
                    distance += leg.distance.value
                    duration += leg.duration.value
                })
                setDistance(`${(distance / 1000).toFixed(1)} km`);
                setDuration(`${(duration / 60).toFixed(1)} min`)
            } else {

            }
        }
    }

    const containerStyle = {
        width: '30vw',
        borderBottomLeftRadius: '45px',
        borderBottomRightRadius: '45px',
        height: '300px',
    };

    return (
        <div className="tripsummary_holder" style={{}}>
            <Container>
                <Grid class='sum-main-hol'>
                    <Grid class='sum-inside'>
                        {data?.bookingType === 'return' &&
                            <Typography className='loctext one-way-h' style={{}}>One-way Details</Typography>
                        }
                        <Grid className='one-point-con'>
                            <Grid container spacing={0} className='one-way-con'>
                                <Grid item className="" xs={12} md={data?.bookingType === 'return' ? 6 : 8} sx={6} >


                                    <Grid container spacing={1} className="location_spot">
                                        <Grid item md={6} xs={12} className="location_startpoint start-point-n" style={{}}>
                                            <Typography className='pic-h'>Pickup Location</Typography>
                                            <MainStop
                                                address={data?.location_from?.address}
                                                count={1}
                                                color="green"

                                            />
                                        </Grid>


                                        {/* <div className="location_midpoint" style={{}}>
                                                {data?.stops?.map((stop, index) => (
                                                    <SubStop
                                                        address={stop?.stop_name?.address}
                                                        count={index + 2}
                                                        color="blue"
                                                        index={index}
                                                        removeStop={(index) => removeExtraStop(index)}
                                                        stopNote={stop?.stopNote}
                                                    />
                                                ))}
                                            </div> */}

                                        <Grid item md={6} xs={12} className="location_stoppoint start-point-n">
                                            <Typography className='pic-h'>Drop Location</Typography>
                                            {/* {data?.location_to?.address &&
                                                    <MainStop
                                                        address={data?.location_to?.address}
                                                        count={data?.stops ? data?.stops?.length + 1 : 2}
                                                        color="red"

                                                    />
                                                } */}
                                            <MainStop
                                                address={data?.location_to?.address}
                                                count={data?.stops ? data?.stops?.length + 1 : 2}
                                                color="red"

                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item className="" xs={12} md={data?.bookingType === 'return' ? 3 : 4} sx={6} >

                                    <Grid container spacing={1} className="sum_listitem sum-p-d">

                                        <Grid item md={6} xs={6} className='loctext'>
                                            <Typography className='p-pos'>Pick Up Date :</Typography><span className='listitem_value list-n' style={{}}>
                                                <Box class='input-con'>
                                                    <span className="img_icon sum-i">
                                                        <CalendarMonthIcon style={{ width: 20, height: 20, color: 'rgb(237 186 55)' }} />

                                                    </span>

                                                    {moment(data?.pickupdate).local().format("DD-MM-YYYY")}
                                                </Box>
                                            </span>
                                        </Grid>


                                        <Grid item md={6} xs={6} className='loctext'>
                                            <Typography className='p-pos'>Pick Up  Time :</Typography><span className='listitem_value list-n' style={{}}>
                                                <Box class='input-con'>
                                                    <span className="img_icon sum-i">

                                                        <AlarmIcon style={{ width: 20, height: 20, color: 'rgb(237 186 55)' }} />

                                                    </span>

                                                    {moment(data?.pickupdate).local().format("hh:mm A")}
                                                </Box>
                                            </span>
                                        </Grid>

                                        {/* {!data?.location_to?.address &&
                                                <Grid item md={3} className='loctext'>
                                                    <Typography>Hours :<span className='listitem_value' style={{}}>
                                                        <Box class='input-con'>
                                                            <span className="img_icon sum-i" >
                                                                <AlarmIcon style={{ width: 20, height: 20, color: 'rgb(237 186 55)' }} />
                                                            </span>
                                                            {data?.duration}
                                                        </Box>
                                                    </span></Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                        {data?.location_to?.address &&


                                            <Grid item md={3} className='loctext'>
                                                <Typography>Estimated Distance & Time :<span className='listitem_value' style={{}}>
                                                    <Box class='input-con'>
                                                        <span className="img_icon" >
                                                            <img src={DistanceImg} />
                                                        </span>
                                                        {distance}, {duration}
                                                    </Box>
                                                </span></Typography>
                                            </Grid>} */}
                                    </Grid>

                                </Grid>

                                {data?.bookingType === 'return' &&
                                    <Grid item md={3} xs={12} className='sum-y-w'>

                                        <Grid className='ret-holder'>

                                            {/* <Typography className='loctext ret-det-h' style={{}}>
                                                Return Details
                                            </Typography> */}

                                            {/* <Grid className='ret-pick-drop'>
                                        <div className="location_startpoint ret-loc" style={{}}>
                                        <Typography className='pic-h'>Pickup Location</Typography>
                                            <MainStop
                                                address={data?.location_to?.address}
                                                count={1}
                                                color="green"
                                            />
                                        </div>
                                        <div className="location_midpoint" style={{}}>
                                            {data?.stops?.map((stop, index) => (
                                                <SubStop
                                                    address={stop?.stop_name?.address}
                                                    count={index + 2}
                                                    color="blue"
                                                    index={index}
                                                    removeStop={(index) => removeExtraStop(index)}
                                                    stopNote={stop?.stopNote}
                                                />
                                            ))}
                                        </div>
                                        <div className="location_stoppoint ret-d-loc">
                                            <Typography className='pic-h'>Drop Location</Typography>
                                                <MainStop
                                                    address={data?.location_from?.address}
                                                    count={data?.stops ? data?.stops?.length + 1 : 2}
                                                    color="red"
                                                />
                                            </div>
                                        </Grid> */}
                                            <Grid container spacing={1} className='point-way-con'>
                                                {data?.bookingType === 'return' &&
                                                    <Grid item md={6} xs={6} className='ret-d-con'>
                                                        <Typography className='point-ret-d-m'>Return Date</Typography>
                                                        <div className="sum_listitem ret-d-f" style={{}}>
                                                            <span className="img_icon sum-i">
                                                                <CalendarMonthIcon style={{ width: 20, height: 20, color: 'rgb(237 186 55)' }} />
                                                            </span>
                                                            <span className='loctext'>
                                                                <span className='listitem_value' style={{}}> {moment(data?.returndate).local().format("DD-MM-YYYY")}</span>
                                                            </span>
                                                        </div>
                                                    </Grid>

                                                }
                                                {data?.bookingType === 'return' &&
                                                    <Grid item md={6} xs={6} className='ret-t-con'>
                                                        <Typography className='point-ret-d-m'>Return Time</Typography>
                                                        <div className="sum_listitem ret-d-f" style={{}}>
                                                            <span className="img_icon sum-i">
                                                                <AlarmIcon style={{ width: 20, height: 20, color: 'rgb(237 186 55)' }} />
                                                            </span>
                                                            <span className='loctext'>
                                                                <span className='listitem_value' style={{ fontFamily: 'Nunito', }}> {moment(data?.returndate).local().format("hh:mm A")}</span>
                                                            </span>
                                                        </div>
                                                    </Grid>

                                                }
                                            </Grid>

                                        </Grid>

                                    </Grid>}

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>




                {data?.location_to?.address &&
                    <Accordion style={{ display: "none", paddingTop: '0.5%', paddingBottom: 0, boxShadow: 'none', left: -20, border: '0px' }} >
                        <AccordionSummary >
                            <Stack>
                                <Button
                                    onClick={() => setLoad(!load)}
                                    variant="contained" justifyContent='center'
                                    style={{ color: '#fff', backgroundColor: '#EDBA37', borderRadius: 20, height: 40, width: 130, left: -35, zIndex: 2 }} >

                                    <h4 color='#fff' className='mapText'>View Map</h4>
                                </Button>
                            </Stack>
                            <Box width={{ xs: '50%', md: "100%" }}>
                                <Divider orientation="horizontal" sx={{ bgcolor: "#EDBA37", height: '2px', marginTop: 2, width: '103.9%', marginLeft: -5 }} />
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails sx={{ position: 'relative', borderRadius: '15px', display: 'flex', justifyContent: 'center', width: '89vw', alignItems: 'center' }}>

                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                zoom={0}
                                clickableIcons={false}
                                options={{
                                    scrollwheel: false,
                                    disableDoubleClickZoom: true,
                                    zoomControl: false,
                                    gestureHandling: 'none',
                                    fullscreenControl: false,
                                    streetView: null,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    disableDefaultUI: true,
                                    styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }]
                                }}
                            >
                                {/* {load && <> */}
                                <DirectionsService
                                    options={{
                                        destination: data?.location_to?.address,
                                        origin: data?.location_from?.address,
                                        waypoints: data?.waypoints,
                                        optimizeWaypoints: false,
                                        travelMode: 'DRIVING',
                                    }}
                                    callback={directionsCallback}
                                    onLoad={directionsService => {

                                    }}
                                    onUnmount={directionsService => {

                                    }}
                                />
                                {directions && <DirectionsRenderer
                                    // required
                                    options={{
                                        directions: directions
                                    }}
                                    // optional
                                    onLoad={directionsRenderer => {
                                    }}
                                    // optional
                                    onUnmount={directionsRenderer => {
                                    }}
                                />}
                                {/* </>} */}
                            </GoogleMap>
                        </AccordionDetails>
                    </Accordion>}
            </Container>

        </div>

    )
}

export default SummaryDetails

