import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import './style.scss'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, MenuItem } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import * as yup from "yup";
import DropdownInput from '../../components/DropdownInput';
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs'
import BorderBox from '../../components/BorderBox'
import cus_reg_bg from '../../assets/images/cus_reg_bg.png'
import company from '../../assets/images/company.png'
import logo from '../../assets/images/brand_logo.png'
import vector_reg from '../../assets/images/vector_reg.png'
import Banner from '../../components/Banner';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const B2BForm = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [country, setCountry] = useState(null)
    const [countrycompany, setCountryCompany] = useState(null)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const { registerSuccess, error } = useSelector(state => state.auth)

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const schema = yup.object({
        firstname: yup.string().required('First name is required').max(100, 'Maximum character exceeded'),
        lastname: yup.string().required('Last name is required').max(100, 'Maximum character exceeded'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email is required').max(150),
        password: yup.string().required('Password is required').min(8, 'Password should contain atleast 8 characters').max(30, 'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password Required").max(30),
        user_type: yup.string().required('User type is required'),
    }).required();

    const { register, handleSubmit, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            company_name: '',
            company_phone: '',
            company_phone_code: '',
            company_email: '',
            company_address: '',
            company_tax: '',
            name: '',
            email: '',
            lastname: '',
            phone: '',
            mobile: '',
            password: ''
        },
        mode: 'onChange'
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }
        if (registerSuccess) {
            setOpen(true)
            reset()
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error, registerSuccess])

    const changePhone = (value) => {
        setCountry(value)
    }

    const changeBussinessPhone = (value) => {
        setCountryCompany(value)
    }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("mobile", country?.phonenumber ? country?.phonenumber : '');
        formData.append("phone", country?.phonenumber ? country?.phonenumber : '');
        formData.append("country_code", country?.callingCode ? country?.callingCode : '');
        formData.append("password", data.password);
        formData.append("company_name", data.company_name);
        formData.append("company_phone", countrycompany?.phonenumber ? countrycompany?.phonenumber : '');
        formData.append("company_phone_code", countrycompany?.callingCode ? countrycompany?.callingCode : '');
        formData.append("company_email", data.company_email);
        formData.append("company_address", data.company_address);
        formData.append("company_tax", data.company_tax);
        formData.append("user_type", data.user_type);
        formData.append("is_corporate", 2);
        dispatch(userRegister(formData))
    };
    return (
        <div>



            <Grid  >
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        User successfully registered
                    </Alert>
                </Snackbar>

                <Grid container justifyContent="center" className='cus-reg-con'>
                    <Grid className='cus-reg-bg' item xs={12} md={6} lg={6} >
                        <img src={cus_reg_bg} alt="" />
                    </Grid>
                    <Grid className='cus-regfrm' item xs={12} md={6} lg={6} >
                    <div className='head_section'>
                            <Grid className='log-ico'>
                            <img src={logo} alt="" />
                        </Grid>
                        <div onClick={() => navigate('/')} style={{  display: 'flex', alignItems: 'center', cursor: 'pointer', padding: 10 }}><ArrowBackIcon style={{ fontSize: 30 }} /> Back to Home</div>
                            
                            </div>
                       
                         <form onSubmit={handleSubmit(onSubmit)}>
                         <Grid className='reg-sub-h'>
                              
                              <h2>Company Registeration</h2>
                              <img src={vector_reg} alt="" />
                              <h4>Sign Up</h4>
                              <Typography>Be a part of <span className='color-h'>DSS LIMOUSINE</span> Today</Typography>
                          </Grid>
                            <FormGroup >
                                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', padding: '2%' }}>
                                    <DropdownInput style={{ width: 300 }} defaultValue="Select User Type" fieldLabel="User Type" fieldName="user_type" control={control} error={errors.user_type} width={260} >

                                        <MenuItem style={{ fontSize: 12, fontFamily: 'Nunito' }} value='1'>Corporate</MenuItem>
                                        <MenuItem style={{ fontSize: 12, fontFamily: 'Nunito' }} value='2'>Travel Agency</MenuItem>
                                    </DropdownInput>
                                </Grid>
                                <Grid container paddingTop={0}>

                                    <Grid className='com-conf'>

                                        <Grid className='reg-input-pos' xs={12} md={12} sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <div className='group_box'>
                                                <h3 className="group_title">Company Details</h3>
                                                <Grid spacing={0.5} container>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="Company Name"
                                                            fieldName="company_name"
                                                            control={control}
                                                            error={errors.company_name}
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="Tax No."
                                                            fieldName="company_tax"
                                                            control={control}
                                                            error={errors.company_tax}
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="Company Email Address"
                                                            fieldName="company_email"
                                                            control={control}
                                                            error={errors.company_email}
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <PhoneInputs
                                                            fieldLabel="Phone Number"
                                                            fieldName="company_phone"
                                                            control={control}
                                                            error={errors.company_phone}
                                                            onChangePhone={changeBussinessPhone}
                                                        />
                                                    </Grid>

                                                    <Grid className='com-input-m' item xs={12} md={12}>
                                                        <InputField
                                                            fieldLabel="Company Address"
                                                            fieldName="company_address"
                                                            control={control}
                                                            error={errors.company_address}
                                                            multiline="multiline"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>

                                        <Grid xs={12} md={12} sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid className='com-reg-side-h'>
                                                <h2>Company Login</h2>
                                                <img src={company} alt="" />
                                                <Typography>Sign Up</Typography>
                                            </Grid>
                                            <div className='group_box'>
                                                <h3 className="group_title">Basic Information</h3>
                                                <Grid className='reg-input-pos' spacing={0.5} container>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="First Name"
                                                            fieldName="firstname"
                                                            control={control}
                                                            error={errors.firstname}
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="Last Name"
                                                            fieldName="lastname"
                                                            control={control}
                                                            error={errors.lastname}
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="Email Address"
                                                            fieldName="email"
                                                            control={control}
                                                            error={errors.email}
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <PhoneInputs
                                                            fieldLabel="Phone Number"
                                                            fieldName="phone"
                                                            control={control}
                                                            error={errors.phone}
                                                            onChangePhone={changePhone}
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="Password"
                                                            fieldName="password"
                                                            control={control}
                                                            error={errors.password}
                                                            fieldType="password"
                                                            placeholder='Password should contain atleast 8 characters'
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <InputField
                                                            fieldLabel="Confirm Password"
                                                            fieldName="confirm_password"
                                                            control={control}
                                                            error={errors.confirm_password}
                                                            fieldType="password"
                                                        />
                                                    </Grid>
                                                    <Grid className='com-input-m' item xs={12} md={6}>
                                                        <Button type="submit" variant="filled" className="primary-btn" style={{}}>
                                                            <Typography className='buttonText'>Register</Typography></Button>

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>

                                
                                </Grid>
                            </FormGroup>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </div>

    )
}

export default B2BForm
